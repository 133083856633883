<script lang="ts" setup>
import type { IBreakdownType } from "@/types";

interface SendBreakdownTypeCardProps {
  modelValue: string;
  title: string;
  options?: string[];
  type: IBreakdownType;
  linkUrl?: string;
  hasOptions?: boolean; // control showing options
}
const { t } = useI18n();
const props = defineProps<SendBreakdownTypeCardProps>();
const emit = defineEmits(["update:modelValue"]);
const innerValue = useVModel(props, "modelValue", emit);
const isActive = computed(() => props.type === innerValue.value);

function onClick() {
  innerValue.value = props.type;
}
</script>

<template>
  <div
    class="flex w-1/2 cursor-pointer rounded border transition-all ease-in dark:text-white"
    :class="[isActive ? 'border-blue-500' : 'border-neutral-light-200']"
    @click="onClick"
  >
    <button class="flex w-full flex-col gap-4 px-4 py-[18px]">
      <div class="flex w-full items-center justify-between text-sm font-bold">
        {{ props.title }}
        <div
          class="relative flex size-4 items-center justify-center rounded-full border border-neutral-light-300"
          :class="[isActive && ['border-none bg-blue-500']]"
        >
          <div
            v-show="isActive"
            class="size-1.5 rounded-full bg-white"
          />
        </div>
      </div>
      <ul
        v-if="hasOptions"
        class="flex max-w-[160px] flex-col gap-2"
      >
        <div
          v-for="option in props.options"
          :key="option"
          class="flex"
        >
          <UIcon
            name="check"
            class="relative top-[6px] pr-3 text-[9px] text-blue-500"
          />
          <li class="relative inline-block text-left text-sm font-medium">
            {{ option }}
          </li>
        </div>
        <div class="flex">
          <a
            :href="props.linkUrl"
            class="text-sm font-medium text-blue-500 underline decoration-[1.5px] underline-offset-2"
          >
            {{ t("pages.breakdowns.send_breakdown.learn_more") }}
          </a>
        </div>
      </ul>
    </button>
  </div>
</template>
