<script setup lang="ts">
import type { IEvent } from "@/types";

const props = defineProps<{
  event: IEvent;
}>();

const user = useUser();
const { t } = useI18n();
const toast = useToast();
const segment = useSegment();

function onClick() {
  segment.track("Vidswap Lineup Edit Opened", formatTrackEvent(props.event));

  return getEditLineupUrl(props.event.id, {
    backUrl: window.location.href,
  })
    .then(url => window.open(url))
    .catch(onError);
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}
</script>

<template>
  <UListItem
    v-if="user && props.event.permissions.canEditOnVS"
    icon="teams"
    :text="t('labels.edit_lineup')"
    :on-click="onClick"
  />
</template>
